import React from "react";
import Layout from "../components/App/Layout";
import Seo from "../components/App/seo";
import Banner from "../components/HomeThree/Banner";
import Services from "../components/Index/Services";
import About from "../components/Index/About";
// import Team from "../components/Index/Team";
import Blog from "../components/Index/Blog";
import VideoArea from "../components/Index/VideoArea";
// import FunFacts from "../components/Index/FunFacts";
import Testimonials from "../components/Index/Testimonials";
import Partner from "../components/Index/Partner";
import Contact from "../components/Index/Contact";

const IndexPage = () => (
  <Layout>
    <Seo title="Dhamira, Inc: Innovative and Experienced Software Engineering" />
    <Banner />
    <div className="mt-130">
      <Services />
    </div>
    <About />
    <VideoArea />
    {/* <Team />
    <FunFacts /> */}
    <Testimonials />
    <Blog />
    <Partner />
    <Contact />
  </Layout>
);

export default IndexPage;
